/* eslint-disable react/prop-types */
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiBadge from "components/SuiBadge";
import { useHistory } from "react-router-dom";
import SuiButton from "components/SuiButton";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import React from "react";
import axios from "axios";

// Images
// import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";
const postData = {};
const token = localStorage.getItem("token");
const finalToken = `Bearer ${token}`;
const axiosConfig = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: finalToken,
  },
};
const data = [
  { name: "Asif", DOB: "left", ID: "1", Address: "left", Phoneno: "author", Status: "left" },
  {
    name: "Ali Jibran",
    DOB: "left",
    ID: "2",
    Address: "left",
    Phoneno: "author",
    Status: "left",
  },
  {
    name: "Ali Asif",
    DOB: "left",
    ID: "3",
    Address: "left",
    Phoneno: "author",
    Status: "left",
  },
  { name: "Qasim", DOB: "left", ID: "4", Address: "left", Phoneno: "author", Status: "left" },
  { name: "Wahid", DOB: "left", ID: "5", Address: "left", Phoneno: "author", Status: "left" },
];
// function Author({ name }) {
//   return (
//     <SuiTypography variant="button" fontWeight="medium">
//       {name}
//     </SuiTypography>
//   );
// }
// const navigate = useNavigate();

// function handleClick() {
//   // this.props.navigation.push('Home')
//   navigate("Home");

// }

function Function({ job, org }) {
  return (
    <SuiBox display="flex" flexDirection="column">
      <SuiTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SuiTypography>
      <SuiTypography variant="caption" color="secondary">
        {org}
      </SuiTypography>
    </SuiBox>
  );
}
function oneRow(row) {
  return {
    name: (
      <SuiTypography variant="h5" fontWeight="medium">
        {row.name}
      </SuiTypography>
    ),
    dob: (
      <SuiTypography variant="h5" fontWeight="medium">
        {row.DOB}
      </SuiTypography>
    ),
    status: (
      <SuiBadge variant="gradient" badgeContent="online" color="success" size="xs" container />
    ),
    insurance: (
      <SuiTypography variant="caption" color="secondary" fontWeight="medium">
        {row.Phoneno}
      </SuiTypography>
    ),
    action: <SuiButton variant="contained">Open</SuiButton>,
  };
}
function getPatients() {
  const res = axios
    .post("https://dttapi.devforhealth.com/api/patients/getPatients", postData, axiosConfig)
    .then((response) => {
      response.data.forEach((row) => {
        data.push({
          name: "ABC",
          DOB: "MY DOB",
          ID: "3",
          Address: "left",
          Phoneno: "author",
          Status: "Active",
        });
      });
    });
}
function Rowsdata() {
  getPatients();
  const datareturned = [];
  data.forEach((row) => {
    const r = oneRow(row);
    datareturned.push(r);
  });
  return datareturned;
}

export default {
  columns: [
    { name: "name", align: "left" },
    { name: "dob", align: "left" },
    { name: "status", align: "center" },
    { name: "insurance", align: "center" },
    { name: "action", align: "center" },
  ],
  rows: Rowsdata(),
};
