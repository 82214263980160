/**
=========================================================
* Soft UI Dashboard React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import axios from "axios";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import Table from "examples/Tables/Table";
import { useEffect, useState } from "react";

import { createBrowserHistory } from "history";

const history = createBrowserHistory({ forceRefresh: true });
function PatientSearch() {
  const columns = [
    { name: "lastName", align: "left" },
    { name: "firstName", align: "left" },
    { name: "dateOfBirth", align: "center" },
    { name: "socialSecurityNumber", align: "center" },
    { name: "status", align: "center" },
  ];
  const [rows, setRows] = useState(null);
  const auth = `Bearer  ${localStorage.getItem("dtt_token")}`;
  const headers = {
    Authorization: auth,
  };
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Patients/getPatients`, { headers })
      .then((response) => setRows(response.data))
      .catch((error) => {
        console.error("There was an error!", error);
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  const openPatientRegistration = (event) => {
    event.preventDefault();
    history.push("/patientsregistration");
    window.location.reload(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiButton sx={{ mb: 5 }} onClick={openPatientRegistration} variant="contained">
          Add New
        </SuiButton>
        <SuiBox mb={3}>
          <Card>
            {/* <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Patients</SuiTypography>
            </SuiBox> */}
            <SuiBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {rows != null && rows.length > 0 ? <Table columns={columns} rows={rows} /> : null}
            </SuiBox>
          </Card>
        </SuiBox>
      </SuiBox>
    </DashboardLayout>
  );
}

export default PatientSearch;
