/* eslint-disable object-shorthand */
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import input from "@mui/material/Input";
import { Grid } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { createBrowserHistory } from "history";
import Checkbox from "@mui/material/Checkbox";

const history = createBrowserHistory({ forceRefresh: true });
function handleSubmit(e) {
  e.preventDefault();
}
function UserRegistration() {
  const [fullName, setFullName] = useState();
  const [email, setEmail] = useState();
  const [gender, setGender] = useState("Male");
  const [password, setPassword] = useState();
  const [confrimPassword, setConfrimPassword] = useState();
  const [userType, setUserType] = useState("Admin");
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zip, setZip] = useState();
  const [contact, setContact] = useState();
  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };
  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
  };
  const [isActive, setIsActive] = useState(true);
  const handleSetIsActive = () => setIsActive(!isActive);
  const auth = `Bearer  ${localStorage.getItem("dtt_token")}`;
  const registerClicked = (event) => {
    event.preventDefault();

    if (password !== confrimPassword) {
      alert("Password and Confirm Password doesnot match");
      return;
    }

    const postData = {
      fullname: fullName,
      email: email,
      password: password,
      gender: gender,
      userType: userType,
      isActive: isActive,
      city: city,
      state: state,
      zipCode: zip,
    };
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: auth,
      },
    };
    axios
      .post("https://dttapi.devforhealth.com/api/Users/userRegistration", postData, axiosConfig)
      .then((response) => {
        history.push("/users");
        window.location.reload(false);
      });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid
        container
        spacing={1}
        alignContent="center"
        justifyContent="center"
        style={{ paddingTop: "50px", paddingLeft: "150px", paddingRight: "150px" }}
      >
        <Grid item xs={6} style={{ width: "300px" }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            Full Name
          </SuiTypography>
        </Grid>
        <Grid item xs={6} style={{ width: "300px" }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            Email
          </SuiTypography>
        </Grid>
        <Grid item xs={6} style={{ width: "300px" }}>
          <SuiInput
            type="input"
            placeholder="Full Name"
            onChange={(e) => setFullName(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} style={{ width: "300px" }}>
          <SuiInput type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
        </Grid>
        <Grid item xs={6} style={{ width: "300px" }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            Password
          </SuiTypography>
        </Grid>
        <Grid item xs={6} style={{ width: "300px" }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            Confirm Password
          </SuiTypography>
        </Grid>
        <Grid item xs={6} style={{ width: "300px" }}>
          <SuiInput
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} style={{ width: "300px" }}>
          <SuiInput
            type="password"
            placeholder="Confirm Password"
            onChange={(e) => setConfrimPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} style={{ width: "300px" }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            User Type
          </SuiTypography>
        </Grid>
        <Grid item xs={6} style={{ width: "300px" }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            Gender
          </SuiTypography>
        </Grid>
        <Grid item xs={6} style={{ width: "300px" }}>
          <Select fullWidth value={userType} label="User Type" onChange={handleUserTypeChange}>
            <MenuItem value="Admin">Admin</MenuItem>
            <MenuItem value="Supervisor">Supervisor</MenuItem>
            <MenuItem value="Guest">Guest</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6} style={{ width: "300px" }}>
          <Select value={gender} label="Gender" onChange={handleGenderChange}>
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6} style={{ width: "300px" }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            City
          </SuiTypography>
        </Grid>
        <Grid item xs={6} style={{ width: "300px" }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            State
          </SuiTypography>
        </Grid>
        <Grid item xs={6} style={{ width: "300px" }}>
          <SuiInput type="input" placeholder="City" onChange={(e) => setCity(e.target.value)} />
        </Grid>
        <Grid item xs={6} style={{ width: "300px" }}>
          <SuiInput type="input" placeholder="State" onChange={(e) => setState(e.target.value)} />
        </Grid>
        <Grid item xs={6} style={{ width: "300px" }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            ZIP Code
          </SuiTypography>
        </Grid>
        <Grid item xs={6} style={{ width: "300px" }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            IsActive
          </SuiTypography>
        </Grid>
        <Grid item xs={6} style={{ width: "300px" }}>
          <SuiInput type="input" placeholder="ZIP Code" onChange={(e) => setZip(e.target.value)} />
        </Grid>
        <Grid item xs={6} style={{ width: "300px" }}>
          <Checkbox checked={isActive} onChange={handleSetIsActive} />
        </Grid>
        <SuiButton
          onClick={registerClicked}
          variant="gradient"
          color="dark"
          fullwidth
          style={{
            marginTop: "30px",
            float: "right",
            marginLeft: "auto",
          }}
        >
          Register
        </SuiButton>
      </Grid>
    </DashboardLayout>
  );
}

export default UserRegistration;
