// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import input from "@mui/material/Input";
import { Grid } from "@mui/material";
import axios from "axios";
import { useState } from "react";
// Data
import authorsTableData from "layouts/patients/data/authorsTableData";
import projectsTableData from "layouts/patients/data/projectsTableData";
import iconButton from "assets/theme/components/iconButton";

import { createBrowserHistory } from "history";

const history = createBrowserHistory({ forceRefresh: true });
function handleSubmit(e) {
  e.preventDefault();
}
function PatientRegistration() {
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [gender, setGender] = useState();
  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={1} alignContent="center" justifyContent="center">
        <Grid item xs={4}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            First Name
          </SuiTypography>
        </Grid>
        <Grid item xs={4}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            Last Name
          </SuiTypography>
        </Grid>
        <Grid item xs={4}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            Gender
          </SuiTypography>
        </Grid>
        <Grid item xs={4}>
          <SuiInput
            type="input"
            placeholder="First Name "
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <SuiInput
            type="input"
            placeholder="Last Last "
            onChange={(e) => setLastName(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <SuiInput type="input" placeholder="Gender" onChange={(e) => setGender(e.target.value)} />
        </Grid>
        <SuiBox
          component="form"
          justifyItems="center"
          alignItems="center"
          sx={{ width: 500, marginTop: 10 }}
        >
          <SuiBox mb={2}>
            <SuiBox mb={1} ml={0.5}>
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                First Name
              </SuiTypography>
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                Last Name
              </SuiTypography>
            </SuiBox>
            <SuiInput
              type="input"
              placeholder="First Name "
              onChange={(e) => setFirstName(e.target.value)}
            />
            <SuiInput
              type="input"
              placeholder="Last Name "
              onChange={(e) => setLastName(e.target.value)}
            />
          </SuiBox>
        </SuiBox>
      </Grid>
    </DashboardLayout>
  );
}

export default PatientRegistration;
