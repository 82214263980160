/**
=========================================================
* Soft UI Dashboard React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import input from "@mui/material/Input";
import axios from "axios";
// Data
import authorsTableData from "layouts/patients/data/authorsTableData";
import projectsTableData from "layouts/patients/data/projectsTableData";
import iconButton from "assets/theme/components/iconButton";
import SuiButton from "components/SuiButton";
import { createBrowserHistory } from "history";

const history = createBrowserHistory({ forceRefresh: true });
function handleSubmit(e) {
  e.preventDefault();
  history.push("/patientsregistration");
  window.location.reload(false);
}
function Tables() {
  const { columns, rows } = authorsTableData;
  console.log("rows: ", rows);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiButton onClick={handleSubmit} sx={{ mb: 5 }} variant="contained">
          Add New
        </SuiButton>
        <SuiBox mb={5}>
          <Card>
            {/* <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Patients</SuiTypography>
            </SuiBox> */}
            <SuiBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={rows} />
            </SuiBox>
          </Card>
        </SuiBox>
      </SuiBox>
    </DashboardLayout>
  );
}

export default Tables;
